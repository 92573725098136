import React from 'react'
import { Link } from 'gatsby'
import Layout from '../layouts/product'
import {Container} from 'semantic-ui-react'
import config from '../config'
const Confirmation = () => (
  <Layout>
    <div style={{ marginTop: '120px', backgroundColor: config.colors.primary }}>
      <div style={{ padding: '5vh' }}>
        <div className="hero">
          <span className="redunderline">Thank you</span> for your Order!
        </div>
      </div>
      <br />
    </div>
    <Container style= {{marginTop: '30px', marginBottom: '30px'}} textAlign="justified">
      <p>
        Your order will be processed shortly, you will also receive a receipt
        via email with your order details. Fulfilment can take upto five days,
        shipping normally takes a couple of extra days within the US. If you
        have not recieved your hat after approximately a week, please feel free
        to contact us.
        <Link to="/#hatstore"> Shop more, because these hats are awesome!</Link>
      </p>
    </Container>
  </Layout>
)

export default Confirmation
